<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12" class="z-index-2">
          <v-card class="bg-transparent mb-6 px-4 py-4">
            <v-row>
              <v-col lg="6">
                <h2 class="text-h2 text-typo font-weight-bolder mb-0">
                  Überblick
                </h2>
              </v-col>
            </v-row>
          </v-card>
          <v-row>
            <v-col  cols="12" md="6">
              <v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize
                        font-weight-bold
                        text-body
                      "
                    >
                      Begriffe
                    </p>
                    <h5 v-if="glossary" class="text-h5 text-typo font-weight-bolder mb-0">
                      {{ glossary.length }}
                    </h5>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        fas fa-chart-line
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="card-shadow border-radius-xl px-4 py-4">
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize
                        font-weight-bold
                        text-body
                      "
                    >
                      Ansichten
                    </p>
                    <h5 v-if="views" class="text-h5 text-typo font-weight-bolder mb-0">
                      {{ views.length }}
                    </h5>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        fas fa-eye
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize
                        font-weight-bold
                        text-body
                      "
                    >
                      Kategorien
                    </p>
                    <h5 v-if="categories" class="text-h5 text-typo font-weight-bolder mb-0">
                      {{ categories.length }}
                    </h5>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        fas fa-list
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="card-shadow border-radius-xl px-4 py-4">
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize
                        font-weight-bold
                        text-body
                      "
                    >
                      Bewertungen
                    </p>
                    <h5 v-if="ratings" class="text-h5 text-typo font-weight-bolder mb-0">
                      {{ ratings.length }}
                    </h5>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white"> fas fa-star </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <!-- <v-col lg="5" class="pt-0 z-index-2">
          <v-card class="card-shadow border-radius-xl px-5 py-5">
            <div class="bg-gradient-default border-radius-lg py-3 pe-1 mb-6">
              <div class="chart-area">
                <canvas :height="170" :id="salesChartID"></canvas>
              </div>
            </div>
            <h6 class="ms-2 mt-6 mb-0 text-h6 text-typo font-weight-bold">
              Aktive Nutzer
            </h6>
           
            <div class="container border-radius-lg ms-2">
              <div class="row">
                <div class="col-6 py-3 ps-0">
                  <div class="d-flex align-center mb-2">
                    <v-avatar
                      size="20"
                      class="
                        text-white
                        bg-gradient-success
                        shadow
                        px-1
                        py-1
                        me-2
                      "
                      rounded
                    >
                    <v-icon size="12" class="text-white">
                        fas fa-user
                      </v-icon>
                    </v-avatar>
                    <p class="text-xs mt-1 mb-0 font-weight-bold text-body">
                      Nutzer
                    </p>
                  </div>
                  <h4 v-if="users" class="font-weight-bolder text-h4 mb-2">{{ users.length }}</h4>
                </div>
                <div class="col-6 py-3 ps-0">
                  <div class="d-flex align-center mb-2">
                    <v-avatar
                      size="20"
                      class="text-white bg-gradient-info px-1 py-1 me-2"
                      rounded
                    >
                    <v-icon size="12" class="text-white">
                        fas fa-eye
                      </v-icon>
                    </v-avatar>
                    <p class="text-xs mt-1 mb-0 font-weight-bold text-body">
                      Klicks
                    </p>
                  </div>
                  <h4 v-if="views" class="font-weight-bolder text-h4 mb-2">{{ views.length}}</h4>
                  
                </div>
                
              </div>
            </div>
          </v-card>
        </v-col> -->
        <v-col lg="6" class="pt-0 z-index-2">
          <v-card class="card-shadow border-radius-xl">
            <div class="card-header-padding pb-0">
              <div class="font-weight-bold text-typo text-h6 text-typo mb-2">
                Meistgesuchte Begriffe
              </div>
              <!-- <p class="text-body text-sm mb-0">
                <i class="fa fa-arrow-up text-success" aria-hidden="true"></i>
                <span class="font-weight-bold ms-1">4% mehr</span> in 2023
              </p> -->
            </div>
            <v-card-text class="card-padding">
              
                <bar-chart-horizontal></bar-chart-horizontal>
              
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="6" class="pt-0 z-index-2">
          <v-card class="card-shadow border-radius-xl">
            <div class="card-header-padding pb-0">
              <div class="font-weight-bold text-typo text-h6 text-typo mb-2">
                Views nach Plattform
              </div>
            </div>
            <v-card-text class="card-padding">
                <pie-chart-views></pie-chart-views>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="6" class="pt-0 z-index-2">
          <v-card class="card-shadow border-radius-xl">
            <div class="card-header-padding pb-0">
              <div class="font-weight-bold text-typo text-h6 text-typo mb-2">
                Nutzer nach Plattform
              </div>
              <!-- <p class="text-body text-sm mb-0">
                <i class="fa fa-arrow-up text-success" aria-hidden="true"></i>
                <span class="font-weight-bold ms-1">4% mehr</span> in 2023
              </p> -->
            </div>
            <v-card-text class="card-padding">
              
                <pie-chart></pie-chart>
              
            </v-card-text>
          </v-card>
        </v-col>
        
      </v-row>
    
    </v-container>
  </div>
</template>

<script>
import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js/auto";
import SalesCountry from "./Widgets/SalesCountry.vue";
import WidgetGlobe from "./Widgets/Globe.vue";
import PieChart from "./Widgets/PieChart.vue"
import PieChartViews from "./Widgets/PieChartViews.vue"
import BarChartHorizontal from "./Widgets/BarChartHorizontal.vue";
export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    WidgetGlobe,
    PieChart,
    BarChartHorizontal,
    PieChartViews
  },
  data: function () {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
    };
  },
 
  computed:{
    glossary(){
      if(this.$store.getters.glossary.data){
        return this.$store.getters.glossary.data;
      } else{
        return []
      }
    },
    ratings(){
      if(this.$store.getters.ratings.data){
        return this.$store.getters.ratings.data;
      } else{
        return []
      }
    },
    categories(){
      return this.$store.getters.categories.data;
    },
    users(){
      return this.$store.getters.users.data;
    },
    views(){
      return this.$store.getters.views.data;
    }
  },
  mounted() {
    this.$store.dispatch("userInformations");
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getUsers");
    this.$store.dispatch("getCategory");
    this.$store.dispatch("getViews");
    this.$store.dispatch("getRatings");
    // new Chart(document.getElementById(this.salesChartID).getContext("2d"), {
    //   type: "bar",
    //   data: {
    //     labels: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    //     datasets: [
    //       {
    //         label: "Nutzer",
    //         tension: 0.4,
    //         borderWidth: 0,
    //         borderRadius: 4,
    //         borderSkipped: false,
    //         backgroundColor: "#fff",
    //         data: [0, 3, 5, 8, 12, 22, 38, 100, 123, 120, 160,180],
    //         maxBarThickness: 6,
    //       },
    //     ],
    //   },
    //   options: {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     plugins: {
    //       legend: {
    //         display: false,
    //       },
    //     },
    //     interaction: {
    //       intersect: false,
    //       mode: "index",
    //     },
    //     scales: {
    //       y: {
    //         grid: {
    //           drawBorder: false,
    //           display: false,
    //           drawOnChartArea: false,
    //           drawTicks: false,
    //         },
    //         ticks: {
    //           suggestedMin: 0,
    //           suggestedMax: 500,
    //           beginAtZero: true,
    //           padding: 15,
    //           font: {
    //             size: 14,
    //             family: "Open Sans",
    //             style: "normal",
    //             lineHeight: 2,
    //           },
    //           color: "#fff",
    //         },
    //       },
    //       x: {
    //         grid: {
    //           drawBorder: false,
    //           display: false,
    //           drawOnChartArea: false,
    //           drawTicks: false,
    //         },
    //         ticks: {
    //           display: false,
    //         },
    //       },
    //     },
    //   },
    // });
    // ordersChart.createChart(this.ordersChartID);
  },
};
</script>
