<template>
  <div class="card-padding">
    <div class="chart">
      <canvas id="pie-chart-views" class="chart-canvas" height="300"></canvas>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "pie-chart-views",
  data: function () {
    return {
      pieChartId: "pie-chart-views",
      myChart: null,
    };
  },
  computed: {
    webUsers() {
      if (!this.glossary) return 0;
      let webUsers = 0;

      this.glossary.forEach((element) => {
        webUsers += element.views.filter((element2) => {
          if (element2.plattform && element2.plattform == "Web") {
            return element2;
          }
        }).length;
      });
      return webUsers;
    },
    iOSUsers() {
      if (!this.glossary) return 0;
      let iOSUsers = 0;

      this.glossary.forEach((element) => {
        iOSUsers += element.views.filter((element2) => {
          if (element2.plattform && element2.plattform == "iOS") {
            return element2;
          }
        }).length;
      });
      return iOSUsers;
    },
    AndroidUsers() {
      if (!this.glossary) return [];
      let androidUsers = 0;

      this.glossary.forEach((element) => {
        androidUsers += element.views.filter((element2) => {
          if (element2.plattform && element2.plattform == "Android") {
            return element2;
          }
        }).length;
      });
      return androidUsers;
    },

    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data.sort(this.compare);
      } else {
        return [];
      }
    },
  },
  watch: {
    glossary() {
      if (this.glossary.length > 0)
        if (this.myChart) {
          this.myChart.destroy();
        }
      this.myChart = new Chart(
        document.getElementById(this.pieChartId).getContext("2d"),
        {
          type: "pie",
          data: {
            labels: ["Web", "iOS", "Android"],
            datasets: [
              {
                label: "Views",
                weight: 9,
                cutout: 0,
                tension: 0.9,
                pointRadius: 2,
                borderWidth: 2,
                backgroundColor: ["#17c1e8", "#FF0000", "#E2B007"],
                data: [
                  this.webUsers,
                  this.iOSUsers,
                  this.AndroidUsers,
                ],
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        }
      );
    },
  },
  mounted() {
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getViews");
  },
};
</script>
