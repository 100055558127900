<template>
    <div class="card-padding">
      <div class="chart">
        <canvas id="pie-chart" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
 
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "pie-chart",
  data: function () {
    return {
      pieChartId: "pie-chart",
      myChart: null
    };
  },
  computed:{
    users(){
      if(!this.$store.getters.users) return [];
      return this.$store.getters.users.data;
    },
    webUsers(){
      if(!this.users) return [];
      return this.users.filter(element => {
        if(element.plattform ){
          return element.plattform.toLowerCase() == 'web'
        }
        if(!element.plattform){
          return element
        }
      } )
    },
    iOSUsers(){
      if(!this.users) return [];
      return this.users.filter(element => {
        if(element.plattform){
          return element.plattform.toLowerCase() == 'ios'
        }
      } )
    },
    AndroidUsers(){
      if(!this.users) return [];
      return this.users.filter(element => {
        if(element.plattform){
          return element.plattform.toLowerCase() == 'android'
        }
      } )
    },
    // undefinedUsers(){
    //   if(!this.users) return [];
    //   return this.users.filter(element => {
    //     if(!element.plattform){
    //       return element
    //     }
    //   } )
    // }
  },
  watch: {
    users(){
      if(this.users.length > 0)
      if(this.myChart){
        this.myChart.destroy();
      }
     this.myChart = new Chart(document.getElementById(this.pieChartId).getContext("2d"), {
      type: "pie",
      data: {
        labels: ["Web", "iOS", "Android"],
        datasets: [
          {
            label: "Nutzer",
            weight: 9,
            cutout: 0,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 2,
            backgroundColor: ["#17c1e8", "#FF0000", "#E2B007", ],
            data: [this.webUsers.length, this.iOSUsers.length, this.AndroidUsers.length],
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    });
    }
  },
  mounted() {
    this.$store.dispatch("getUsers");
  },
};
</script>
