<template>
  <div class="card-padding">
    <div class="chart">
      <canvas
        id="bar-chart-horizontal"
        class="chart-canvas"
        height="300"
      ></canvas>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "bar-chart-horizontal",
  data: function () {
    return {
      barChartHorizontalId: "bar-chart-horizontal",
      myChart: null
    };
  },
  computed: {
    viewsLabels() {
      let array = [];
      for (const iterator of this.glossary) {
        array.push(iterator.expression);
      }
      return array;
    },
    viewsCount() {
      let array = [];
      for (const iterator of this.glossary) {
        array.push(iterator.views.length);
      }
      return array;
    },
    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data
          .slice(0, 10)
          .sort(this.compare);
      } else {
        return [];
      }
    },
  },
  methods: {
    compare(a, b) {
      if (a.views.length > b.views.length) {
        return -1;
      }
      if (a.views.length < b.views.length) {
        return 1;
      }
      return 0;
    },
  },
  watch: {
    glossary() {
      if (this.glossary.length > 0)
        if (this.myChart) {
          this.myChart.destroy();
        }
      this.myChart = new Chart(
        document.getElementById(this.barChartHorizontalId).getContext("2d"),
        {
          type: "bar",
          data: {
            labels: this.viewsLabels,
            datasets: [
              {
                label: "Anzahl Ansichten",
                weight: 5,
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: "#3A416F",
                data: this.viewsCount,
                fill: false,
              },
            ],
          },
          options: {
            indexAxis: "y",
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#9ca2b7",
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: true,
                },
                ticks: {
                  display: true,
                  color: "#9ca2b7",
                  padding: 10,
                },
              },
            },
          },
        }
      );
    },
  },
  mounted() {
    this.$store.dispatch("getGlossary");
  },
};
</script>
